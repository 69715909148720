 <template>
     <div v-if="loaded">
      <vs-row>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="6" sm="12">
       <h2 v-if="userdata.userType === 9" style="text-align:left"><a href="/#/installations/">INSTALLATIONS</a> <span style="font-weight: 400">/ {{ ID }}</span></h2>
       <h2 v-if="userdata.userType === 2" style="text-align:left">{{ ID }}</h2>
      </vs-col>
      <vs-col class="float-right" vs-type="flex" vs-justify="right" vs-align="right" style="text-align: right"  w="6" sm="12">
        <vs-button
        flat
        :active="true"
        @click="goto('devices')"
      >
        Devices
      </vs-button><vs-button
        flat
        :active="true"
        @click="update"
      >
        Update
      </vs-button>
      <vs-button
      v-if="userdata.userType === 9" 
        flat
        danger
        @click="deleteInstallation"
      >
        Delete
      </vs-button>
      </vs-col>
    </vs-row>

     <vs-row style="margin-top: 30px">
        <vs-col offset="2" w="8" sm="12">
           <vs-row style="margin-bottom: 20px">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="2" sm="5">
         Installation ID:
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="10" sm="7">
            {{ InstallationId }}
        </vs-col>
      </vs-row>
        <vs-row style="margin-bottom: 20px">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="2" sm="12">
         Name:
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="10" sm="12">
              <vs-input v-model="InstallationName" placeholder="Name" />
        </vs-col>
      </vs-row>
        <vs-row style="margin-bottom: 20px">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="2" sm="12">
         E-mail:
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="10" sm="12">
              <vs-input v-model="InstallationEmail" placeholder="E-mail" />
        </vs-col>
      </vs-row>
        <vs-row style="margin-bottom: 20px">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="2" sm="12">
         Password:
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="10" sm="12">
               <vs-input type="password" icon-after v-model="InstallationPass"
        :visiblePassword="true" @click-icon="genPassword">
        <template #icon>
          <i class='bx bx-reset'></i>
        </template>
      </vs-input>
        </vs-col>
      </vs-row>
        <vs-row style="margin-bottom: 20px">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="2" sm="3">
        Devices:
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="10" sm="9">
              {{ devices.length }}
        </vs-col>
      </vs-row>
      
        <vs-row style="margin-bottom: 20px">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="2" sm="12">
        Require User Info
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="word-break: break-all;text-align: left" w="10" sm="12">
          <vs-switch v-model="reqUserInfo" style="width: 50px; margin-left: 20px" />
        </vs-col>
      </vs-row>
        <vs-row style="margin-bottom: 20px">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="text-align: left" w="2" sm="12">
        Public URL:
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" style="word-break: break-all;text-align: left" w="10" sm="12">
              <a :href="url + InstallationSlug" target="_blank">{{url + InstallationSlug}}</a>
        </vs-col>
      </vs-row>
      

        </vs-col>
      </vs-row>


    </div>
  </template>
  <script>
    var generator = require('generate-password');
    import 'sweetalert2/dist/sweetalert2.min.css';

    export default {
      data:() => ({
        ID:'',
        InstallationName: '',
        InstallationEmail: '',
        InstallationPass: '',
        InstallationSlug: '',
        devices: [],
        InstallationId: '',
        hasVisiblePassword: false,
        editActive: false,
        edit: null,
        editProp: {},
        search: '',
        allCheck: false,
        page: 1,
        max: 10,
        active: false,
        reqUserInfo: false,
        selected: [],
        loaded: false,
        url: 'https://univrse.workpreview.pro/#/installation/',
      }),
      props: ['userdata', 'instId'],
      mounted(){
        if (this.userdata.userType === 9){
        this.ID = this.$route.params.id
        this.InstallationId = this.ID
        this.getInstallation()
        } else if (this.userdata.userType === 2){
        this.ID = this.instId
        this.InstallationId = this.ID
        this.getInstallation()
        } else{
          this.$router.push('/');
        }
      },
      methods: {
        goto(page){
          if (this.userdata.userType === 9){
            this.$router.push('/installations/' + this.InstallationId + '/' + page);
          } else {
             this.$router.push('/devices');
          }
        },
        getInstallation(){
          this.$http.get('/get-installation/' + this.InstallationId).then(response => {
            console.log(response)
            const data = response.data.response
            this.InstallationId =  parseInt(data.id)
            this.InstallationName = data.name
            this.InstallationEmail = data.email
            this.InstallationSlug = data.slug
            this.devices = data.devices
            this.reqUserInfo = (data.userInfo === 1)
            this.ID = this.InstallationName
            this.loaded = true
          })
        .catch(() => {
          if (this.userdata.userType === 9){
          this.$router.push('/installations');
          } else {
          this.$router.push('/error');
          }
        });
      },
        genPassword(){
          var password = generator.generate({
              length: 10,
              numbers: true
            });
            this.InstallationPass = password;
        },
        update() {
          if (this.InstallationPass !== ''){
          this.$swal.fire({
          title:  'Are you sure?',
          text:  'Password will be changed',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, update it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.updateData(() =>{
              this.$swal.fire({
                title:  'Updated!',
                text:  'User data has been updated.',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6'
              })
            })
          }
        })
          } else{
            this.updateData(() =>{
              this.$swal.fire({
                title:  'Updated!',
                text:  'User data has been updated.',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6'
              })
            })
          }
        },
        updateData(callback){
          const userInfo = (this.reqUserInfo) ? 1 : 0
          const data ={ installationName: this.InstallationName, email:this.InstallationEmail, pass: this.InstallationPass, userInfo }
           this.$http.put('/installation/' + this.InstallationId, JSON.stringify(data)).then(() => {
              this.getInstallation()
              callback(true)
           })
        },
        deleteInstallation() {
          this.$swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$http.delete('/installation/' + this.InstallationId).then(() => {
              this.$swal.fire(
                'Deleted!',
                'User has been deleted.',
                'success'
              )
              this.$router.push('/installations/');
           })
          }
        })
        },
    }
    }
    </script>
    <style type="text/css">
    .float-right button{
        float:right;
    }
      .th-center div{
      text-align:center!important
    }
    .inline input{
        margin-right:20px !important;
    }
    input{
        width: 100%!important;
    }
    .limits{
     margin-top: 30px;
    background-color: whitesmoke;
    padding: 20px;
    font-weight: 700;
    font-size: larger;
    }
    </style>
