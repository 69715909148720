import axios from "axios";
import Vue from 'vue'
let url = "https://univrse.workpreview.pro/api"
    //let url = "http://localhost:9090/api"

const apiInstance = createInstance(url);
const apiInstanceFile = createInstanceFile(url);

function createInstance(baseURL) {
    return axios.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
            'authentication': 'bafc7a4e818ed1c6692044eaeb7ef870'
        }
    });
}

function createInstanceFile(baseURL) {
    return axios.create({
        baseURL,
        headers: {
            'authentication': 'bafc7a4e818ed1c6692044eaeb7ef870',
            'Content-Type': 'multipart/form-data'
        }
    });
}

export default {
    install() {
        Vue.prototype.$http = apiInstance
        Vue.prototype.$httpFile = apiInstanceFile
    }
}; // Check debug/build mode